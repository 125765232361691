import { FuseConfig } from '@fuse/types';

export const fuseConfig: FuseConfig = {
  colorTheme: 'theme-default',
	customScrollbars: true,
	banner: 'https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-al/banner.png',
	bgPage: '',
	bgMain: '',
	directorLogo: 'https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-al/directorLogo.png',
	hqLogo: '',
	smallLogo: 'https://picture.nyc3.cdn.digitaloceanspaces.com/customize-images/usasb-al/smallLogo.jpeg',
    layout : {
        style    : 'vertical-layout-1',
        width    : 'fullwidth',
        navbar   : {
            primaryBackground: 'custom-primary-500',
            secondaryBackground: 'grey-100',
			customPrimaryBackground: '',
			customPrimaryForeground: '',
			customSecondaryBackground: '',
			customSecondaryForeground: '',
            folded             : false,
            hidden             : false,
            position           : 'left',
            variant            : 'vertical-style-2'
        },
		toolbar: {
			toolBarLogo: '',
            customBackgroundColor: true,
            background           : 'fuse-white-500',
			customToolbarBackground: '',
          	customToolbarForeground: '',
            hidden               : false,
            position             : 'below-static'
        },
        footer   : {
            customBackgroundColor: true,
            background           : 'custom-accent-500',
			customFooterBackground: '',
			customFooterForeground: '',
            hidden               : false,
			position: 'below-static',
			email: '',
			facebook: 'https://www.facebook.com/AlabamaUSAFastpitch/',
			instagram: '',
			twitter: 'https://twitter.com/usasoftballofal',
			youtube: '',
			linkedin: '',
			forum: ''
        },
        sidepanel: {
            hidden  : false,
            position: 'right'
        },
        pageheader: {
            primaryBackground: 'custom-accent-500',
			customHeaderPrimaryBackground: '',
			customHeaderPrimaryForeground: ''
        }, 
        linkActiveColor: {
            primaryBackground: 'custom-accent-500',
            customLinkBackground: '',
            customLinkForeground: ''
        }
  }
};
